import React from 'react';
import {Dropdown, Input, Select} from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import './style.scss';
import SelectCityIcon from '../Icons/SelectCity';
import * as applicationActions from '../../actions/applicationActionCreators';

const SelectCity = (props) => {
  const handleSelectCity = (cityId) => () => {
    props.applicationActions.setCurrentCity(cityId);
  };
  return (
    <div className='select-city'>
      <button className='select-city__selector'>
        <SelectCityIcon />
        <span className='select-city__title'>{props.applicationState.current_city.name}</span>
      </button>
      <div className='select-city__dropdown'>
        {
          props.applicationState.cities.map((city) => (
            <button key={city.id} onClick={handleSelectCity(city.id)}>{city.name}</button>
          ))
        }
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState, railsContext}) => {
    return {
      applicationState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(SelectCity);
