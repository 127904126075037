import SearchIcon from '../../../Icons/Search';
import React, { useEffect, useRef } from 'react';
import './style.scss';

const MobileSearchString = ({onChange, query, onClear = () => {}, onFocus = () => {}, placeholder = "Поиск", showClear = true, ...props}) => {
  const handleClear = (e) => {
    e.preventDefault();
    onClear && onClear();
  }

  const textInput = useRef(null);

  useEffect(() => {
    if(props.autofocus) {
      textInput.current?.focus();
      setTimeout(() => { textInput.current?.focus(); }, 50);
    }
  }, [props.autofocus, textInput]);


  return (
    <div className='mobile-search-string__search-row'>
      <div className='mobile-search-string__search-wrapper'>
              <span className='mobile-search-string__search-icon'>
                <SearchIcon color='#000' height={24} width={24} />
              </span>
        <input
          value={query}
          ref={textInput}
          onFocus={onFocus ? onFocus : null}
          className='mobile-search-string__search'
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
      <button className={`mobile-search-string__clear ${query && showClear ? 'active' : ''}`} onClick={handleClear}>Отмена</button>
    </div>
  );
}

export default MobileSearchString;
