import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import './style.scss';
import './mobile.scss';
import RubricsPreviewList from '../RubricsPreviewList';
import TextSnippet from '../TextSnippet';
import SubscriptionForm from '../Forms/Subscription';
import Slider from '../Slider';
import {brandProductCollectionKey} from '../../reducers/brands';
import ProductsList from '../ProductsList';

const BrandShow = (props) => {
  const
    {currentBrand} = props.brandsState,
    {is_mobile, current_city} = props.applicationState,
    offersTypes = [
      {type: 'hits', title: 'Популярные товары'},
      {type: 'stocks', title: 'Акции'},
      {type: 'latests', title: 'Новинки'}
    ];

  if (!currentBrand) {
    return ;
  }
  return (
    <>
      <h1>{currentBrand?.title}</h1>
      <section className='page-content-section'>
        <RubricsPreviewList rubrics={props.brandsState.currentBrandRubrics} />
      </section>
      {
        offersTypes.map((options: any) => {
          const offers = props.brandsState[brandProductCollectionKey(options.type)];
          return offers.length ? (
            <section className='page-content-section'>
              <h3>
                {options.title}
              </h3>
              <ProductsList products={offers} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
            </section>
          ) : null;
        })
      }
      <section className='page-content-section'>
        <h3>
          Купить {currentBrand?.title}
        </h3>
        <TextSnippet text={currentBrand.content} drawerTitle={currentBrand.title} />
      </section>

      <section className='page-content-section message-form-section'>
        {
          is_mobile ?
            <div className='message-form__wrapper'>
              <SubscriptionForm />
            </div>
            : <>
              <div className='message-form-section__col message-form-section__col_1'>
                <Slider slides={props.applicationState.homeSlidesBottom} className='bottom' />
              </div>
              <div className='message-form-section__col message-form-section__col_2'>
                <div className='message-form__wrapper'>
                  <SubscriptionForm />
                </div>
              </div>
            </>
        }
      </section>
    </>
  );
};

export const
  mapStateToProps = ({applicationState, brandsState}) => {
    return {
      applicationState,
      brandsState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(BrandShow);
