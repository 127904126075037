import ACTIONS from '../constants/actions';

export const brandProductCollectionKey = (type: 'latests' | 'hits' | 'stocks') => {
  const TYPES = {
    latests: 'currentBrandLatestOffers',
    hits: 'currentBrandHitOffers',
    stocks: 'currentBrandStockOffers',
  };

  return TYPES[type];
}

export const initialState = {
  popular_brands: [],
  popular_brands_loaded: false,
  collection: [],
  collection_loaded: false,
  currentBrand: null,
  currentBrandRubrics: [],
  currentBrandRubricsLoaded: false,
  [brandProductCollectionKey('latests')]: [],
  [brandProductCollectionKey('hits')]: [],
  [brandProductCollectionKey('stocks')]: [],
};

const brandsReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.FETCH_BRANDS_SUCCESS]() {
      return {
        ...state,
        collection: action.payload.brands
      };
    },

    [ACTIONS.RESET_BRANDS]() {
      return {
        ...state,
        collection: [],
        collection_loaded: false
      };
    },

    [ACTIONS.FETCH_BRAND_SUCCESS]() {
      return {
        ...state,
        currentBrand: action.payload.brand
      };
    },

    [ACTIONS.RESET_CURRENT_BRAND]() {
      return {
        ...state,
        currentBrand: null
      };
    },

    [ACTIONS.FETCH_POPULAR_BRANDS_SUCCESS]() {
      return {
        ...state,
        popular_brands: action.payload.brands
      };
    },

    [ACTIONS.RESET_POPULAR_BRANDS]() {
      return {
        ...state,
        popular_brands: [],
        popular_brands_loaded: false
      };
    },
    [ACTIONS.FETCH_BRAND_RUBRICS_SUCCESS]() {
      return {
        ...state,
        currentBrandRubrics: action.payload.rubrics,
        currentBrandRubricsLoaded: true
      };
    },

    [ACTIONS.RESET_BRAND_RUBRICS]() {
      return {
        ...state,
        currentBrandRubrics: [],
        currentBrandRubricsLoaded: false
      };
    },
    [ACTIONS.FETCH_BRAND_PRODUCTS_SUCCESS]() {
      return {
        ...state,
        [brandProductCollectionKey(action.payload.type)]: action.payload.products,
      };
    },
    [ACTIONS.RESET_BRAND_PRODUCTS]() {
      return {
        ...state,
        [brandProductCollectionKey('latests')]: [],
        [brandProductCollectionKey('hits')]: [],
        [brandProductCollectionKey('stocks')]: [],
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default brandsReducer;
