import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import * as cartActions from '../../actions/cartActionCreators';
import {useMount} from 'react-use';
import Cart from '../../components/Cart';
import constants from '../../constants';
import {productsCountFrom} from '../../tools/formatters';

const CartPage = (props) => {
  const
    {cartState, applicationState} = props,
    {is_mobile} = applicationState;

  useMount(() => {
    props.cartActions.fetchCart();
  });

  return (
    <Layout
      breadcrumbs={props.applicationState.is_mobile ? null : [
        {label: 'Корзина'}
      ]}
      className='cart-page'
    >
      <div className='container'>
        <h1>
          Корзина
          {
            is_mobile ?
              <>
                &nbsp;
                <div className='counters'>{cartState?.info.qty || 0} {productsCountFrom(cartState?.info.qty || 0)}</div>
              </>
              : null
          }
        </h1>
        <Cart />
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({cartState, railsContext, applicationState}) => {
    return {
      applicationState,
      cartState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(CartPage));
