import React from 'react';
import ChildrenRubricList from '../../../components/MobileChildrenRubricsList';
import ChildrenRubricsBricks from '../../../components/ChildrenRubricsBricks';
import {bindActionCreators, compose} from 'redux';
import * as rubricsActions from '../../../actions/rubricsActionCreators';
import {connect} from 'react-redux';

const RubricContent = ({rubric, ...props}) => {
  const {is_mobile} = props.applicationState;
  return (
    <>
      {
        is_mobile ?
          <ChildrenRubricList rubrics={rubric.children} />
          : <ChildrenRubricsBricks rubrics={rubric.children} />
      }
    </>
  )
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      rubricsActions: bindActionCreators(rubricsActions, dispatch)
    };
  },
  mapStateToProps = ({rubricsState, railsContext, applicationState}) => {
    return {
      applicationState,
      rubricsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(RubricContent);

