import React from 'react';

import './style.scss';
import './mobile.scss';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {Input} from 'antd';
import CloseIcon from '../../Icons/Close';

const OrderUserForm = ({applicationState, ...props}) => {
  return (
    <div className='form form_user'>
      <div className='form__row'>

      </div>
      <div className='form__row'>
        <div className='form__field'>
          <div className='form__field-label'>Фамилия</div>
          <div className='form__field-input'>
            <Input suffix={<CloseIcon />} />
          </div>
        </div>
        <div className='form__field'>
          <div className='form__field-label'>Имя</div>
          <div className='form__field-input'>
            <Input />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(OrderUserForm);

