import React from 'react';
import {Input} from 'antd';
import './style.scss';
import SearchIcon from '../../Icons/Search';

const Search = () => {
  return (
    <div className='search-bar'>
      <button className='search-bar__search-button'><SearchIcon /></button>
      <Input placeholder='Поиск по товарам' />
    </div>
  );
};

export default Search;
