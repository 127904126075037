import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as productsActions from '../actions/productsActionCreators';
import {extractPaginationParams} from '../tools/request';

const {API, ACTIONS} = constants;

export const fetchProductsRequest = (params) => RequestTools.ajax({url: API.PRODUCTS_PATH, params: params});
export const fetchFiltersRequest = (params) => RequestTools.ajax({url: API.PRODUCTS_FILTERS_PATH, params: params});
export const fetchProductRequest = (id) => RequestTools.ajax({url: API.PRODUCT_PATH(id)});
export const fetchAnalogueProductsRequest = (id, params = {}) => RequestTools.ajax({url: API.ANALOGUE_PRODUCT_PATH(id), params: params});
export const fetchInterestsProductsRequest = (id, params = {}) => RequestTools.ajax({url: API.INTERESTS_PRODUCT_PATH(id), params: params});
export const fetchProductReviewsPath = (id, params = {}) => RequestTools.ajax({url: API.PRODUCT_REVIEWS_PATH(id), params: params});

const buildFetchProductsFilters = (filters) => {
  const filterParams: any = {};

  if (filters.price) {
    filterParams.price_from = filters.price[0];
    filterParams.price_to = filters.price[1];
  }

  if (filters.brands) {
    filterParams.brand_ids = filters.brands;
  }

  if (filters.traits) {
    filterParams.traits = filters.traits;
  }

  if (filters.without_price) {
    filterParams.without_price = filters.without_price;
  }

  if (filters.scope_filters) {
    filterParams.scopes = filters.scope_filters;
  }

  if (filters.reminder) {
    filterParams.reminder = filters.reminder;
  }

  return filterParams;
}

export function * fetchProducts(action) {
  const
    {productsState} = yield select(),
    params: any = {rubric_id: action.payload.rubricId},
    {paginator: {page, per_page}, filters, sort} = productsState,
    filterParams: any = buildFetchProductsFilters(filters),
    hasFilters = !!Object.keys(filterParams).length;


  if (hasFilters) {
    params.q = filterParams;
  }

  params.sort_name = sort.name;
  params.sort_dest = sort.dest;

  params.page = page;
  params.per_page = per_page;

  yield put(productsActions.resetProducts());

  try {
    const
      response = yield call(fetchProductsRequest, params);

    yield put(productsActions.fetchProductsSuccess(response.data, extractPaginationParams(response)));
  } catch (e) {
    yield put(productsActions.fetchProductsFailed());
  }
}

export function * fetchProduct(action) {
  yield put(productsActions.resetCurrentProduct());
  try {
    const response = yield call(fetchProductRequest, action.payload.productId);

    yield put(productsActions.fetchProductSuccess(response.data));
    yield put(productsActions.fetchInterestingProducts(action.payload.productId));
    yield put(productsActions.fetchAnalogueProducts(action.payload.productId));
    yield put(productsActions.fetchProductReviews(action.payload.productId));
  } catch (e) {
    yield put(productsActions.fetchProductFailed());
  }
}

export function * fetchProductsFilters(action) {
  try {
    const filtersResponse = yield call(fetchFiltersRequest, {rubric_id: action.payload.rubricId});
    yield put(productsActions.fetchProductsFilterContextSuccess(filtersResponse.data));
  } catch (e) {
    yield put(productsActions.fetchProductsFilterContextFailed());
  }
}

export function * fetchAnalogueProducts(action) {
  yield put(productsActions.resetAnalogueProducts());
  try {
    const response = yield call(fetchAnalogueProductsRequest, action.payload.productId, {page: 1, per_page: 6});
    yield put(productsActions.fetchAnalogueProductsSuccess(response.data));
  } catch (e) {
    yield put(productsActions.fetchAnalogueProductsFailed());
  }
}

export function * fetchInterestingProducts(action) {
  yield put(productsActions.resetInterestingProducts());
  try {
    const response = yield call(fetchInterestsProductsRequest, action.payload.productId, {page: 1, per_page: 6});
    yield put(productsActions.fetchInterestingProductsSuccess(response.data));
  } catch (e) {
    yield put(productsActions.fetchInterestingProductsFailed());
  }
}

export function * fetchReviews(action) {
  yield put(productsActions.resetProductReviews());
  try {
    const response = yield call(fetchProductReviewsPath, action.payload.productId, {page: 1, per_page: 6});
    yield put(productsActions.fetchProductReviewsSuccess(response.data));
  } catch (e) {
    yield put(productsActions.fetchProductReviewsFailed());
  }
}

export function * reloadProducts() {
  const
    {rubricsState} = yield select();
  yield put(productsActions.fetchProducts(rubricsState.currentRubric.id));
}

export function * loadPossibleProductsCount() {
  const
    {productsState, rubricsState} = yield select(),
    params: any = {rubric_id: rubricsState.currentRubric.id},
    {filters} = productsState,
    filterParams: any = buildFetchProductsFilters(filters),
    hasFilters = !!Object.keys(filterParams).length;

  if (hasFilters) {
    params.q = filterParams;
  }
  params.page = 1;
  params.per_page = 1;

  try {
    const
      response = yield call(fetchProductsRequest, params),
      paginator = extractPaginationParams(response);

    yield put(productsActions.fetchProductsCountSuccess(paginator.total));
  } catch (e) {
    yield put(productsActions.fetchProductsCountFailed());
  }

}

export function * filterChange() {
  yield put(productsActions.fetchProductsCount());
}

function * productsSaga() {
  yield takeEvery(ACTIONS.FETCH_PRODUCTS, fetchProducts);
  yield takeEvery(ACTIONS.FETCH_PRODUCTS_FILTERS_CONTEXT, fetchProductsFilters);
  yield takeEvery([
    ACTIONS.SET_PRODUCTS_PAGINATION,
    ACTIONS.RESET_PRODUCTS_FILTERS,
    ACTIONS.APPLY_PRODUCTS_FILTERS,
    ACTIONS.CHANGE_PRODUCTS_SORT
  ], reloadProducts);
  yield takeEvery([
    ACTIONS.SET_PRODUCTS_FILTER
  ], filterChange);
  yield takeEvery([
    ACTIONS.FETCH_PRODUCTS_COUNT
  ], loadPossibleProductsCount);
  yield takeEvery(ACTIONS.FETCH_PRODUCT, fetchProduct);
  yield takeEvery(ACTIONS.FETCH_ANALOGUE_PRODUCTS, fetchAnalogueProducts);
  yield takeEvery(ACTIONS.FETCH_INTERESTING_PRODUCTS, fetchInterestingProducts);
  yield takeEvery(ACTIONS.FETCH_PRODUCT_REVIEWS, fetchReviews);
}

export default productsSaga;
