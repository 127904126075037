import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as brandsActions from '../../actions/brandsActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount} from 'react-use';
import BrandsPreviewList from '../../components/BrandsPreviewList';

const BrandsPage = (props) => {
  const
    collection = props.brandsState.collection;

  useMount(() => {
    props.brandsActions.fetchBrands();
  });

  return (
    <Layout
      className='brands-page'
      breadcrumbs={[
        {
          label: 'Бренды'
        }
      ]}
    >
      <div className='container'>
        <section className='page-content-section'>
          <BrandsPreviewList brands={collection} mobileInline={false} />
        </section>
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      brandsActions: bindActionCreators(brandsActions, dispatch)
    };
  },

  mapStateToProps = ({brandsState, railsContext, applicationState}) => {
    return {
      applicationState,
      brandsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(BrandsPage));
