import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as articlesActions from '../../actions/articlesActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount, useUnmount} from 'react-use';
import {extractBrandId} from '../../routes/routes';
import ArticleShow from '../../components/Articles/Show';

const StockPage = (props) => {
  const
    [currentPath, setCurrentPath] = useState(null),
    stockPath = props.match.params.id,
    currentStock = props.articlesState.currentStock;

  useEffect(() => {
    if (stockPath === currentPath) return;
    const stockId = extractBrandId(stockPath);
    props.articlesActions.fetchStock(stockId);
    setCurrentPath(stockPath);
  }, [stockPath, props.articlesActions.fetchStock]);

  useUnmount(() => {
    props.articlesActions.resetStock();
  });

  return (
    <Layout
      className='stock-page'
      breadcrumbs={[
        {label: 'Акции', path: constants.ROUTES.REGIONAL_STOCKS_PATH(props.applicationState.current_city.slug)},
        {label: currentStock?.title}
      ]}
    >
      <div className='container'>
        <ArticleShow article={currentStock} />
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      articlesActions: bindActionCreators(articlesActions, dispatch)
    };
  },

  mapStateToProps = ({articlesState, railsContext, applicationState}) => {
    return {
      applicationState,
      articlesState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(StockPage));
