export const productInCart = (cart, storeProductId: number) => {
  if (!cart?.cart_items.length) { return null; }
  const cartItem = cart.cart_items.find(item => item.store_product.id === storeProductId);
  return cartItem?.qty || null;
};

export const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const productInFavourites = (favourites, storeProductId: number) => {
  if (!favourites.length) { return false; }
  const favourite = favourites.find(item => item.store_product.id === storeProductId);
  return !!favourite;
};

export const getDataFromGeoCoderResult = (geoCoderResponse) => {
  const
    geoCoderResponseHead = geoCoderResponse,
    geoCoderData = geoCoderResponseHead.address_components,
    isEmptyData = !geoCoderResponseHead || !geoCoderData;

  if (isEmptyData) return {};

  const result = geoCoderData.reduce((acc, {types, long_name: value}) => {
    const type = types[0];

    switch (type) {
      case 'route':
        return {...acc, street: value};
      case 'locality':
        return {...acc, city: value};
      case 'postal_town':
        return {...acc, city: value};
      case 'administrative_area_level_1':
        return {...acc, county: value};
      case 'country':
        return {...acc, country: value};
      case 'postal_code_prefix':
        return {...acc, postalCodePrefix: value};
      case 'street_number':
        return {...acc, streetNumber: value};
      case 'postal_code':
        return {...acc, eircode: value};
      default:
        return acc;
    }
  }, {});

  result.address = geoCoderResponseHead.formatted_address;
  return result;
};

export const stripTags = (string: string) => {
  return string?.replace(/(<([^>]+)>)/ig, '');
};

export const buildFaqAnchor = (id: string): string => `#question_${id}`;
export const faqItemIdFromAnchor = (anchor: string): number | null => Number(anchor.split('_')[1]) || null;

export const postRedirect = (url: string, params: any) => {
  const form = document.createElement('form');

  form.action = url;
  form.method = 'POST';

  for (const key in params) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
};

export const inGroupsOf = (arr: unknown[], n: number): unknown[] => {
  const
    items = cloneObject(arr),
    chunks = [],
    length = items.length;

  for (let i = 0; i < n; i++) {
    if (!items.length) { return chunks; }
    chunks.push(items.splice(0, Math.ceil(length / n)));
  }

  return chunks;
};

export const isLocalUrl = (path) => !path.includes('http');

export const inGroups = (arr: unknown[] = [], groupsCount: number): unknown[] => {
  const result = [];
  let i = 0;
  arr.forEach((item) => {
    result[i] = result[i] || [];
    result[i].push(item);
    if (i === groupsCount - 1) {
      i = 0;
    } else {
      i++;
    }
  });

  return result;
};


export const setCookie = (name: string, value: any, options: any = {}): void => {
  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const getCookie = (name: string): string => {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const downloadURI = (uri, name) =>
{
  var link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
