import ACTIONS from '../constants/actions';

export const initialState = {
  suggestions: {
    words: [],
    offers: [],
    brands: [],
    highlighter: [],
    rubrics: [],
    search_phrase: '',
    empty: false
  },
};

const searchReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.FETCH_SEARCH_SUGGESTIONS_SUCCESS]() {
      return {
        ...state,
        suggestions: {
          ...action.payload.suggestions
        }
      };
    },
    [ACTIONS.RESET_SEARCH_SUGGESTIONS]() {
      return {
        ...state,
        suggestions: {
          words: [],
          offers: [],
          brands: [],
          highlighter: [],
          rubrics: [],
          search_phrase: '',
          empty: false
        }
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default searchReducer;
