import {applyMiddleware, compose, createStore, combineReducers} from 'redux';

import _ from 'lodash';
import reducers, {initialStates} from '../reducers';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import RootSaga from '../sagas';

export default (props, railsContext) => {
  const initialState = {
      ..._.merge(JSON.parse(JSON.stringify(initialStates)), props),
      railsContext,
    },

    sagaMiddleware = createSagaMiddleware(),
    reduxMiddlewares = [];

  reduxMiddlewares.push(sagaMiddleware);

  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    reduxMiddlewares.push(logger);
  }

  const reducer = combineReducers({
      ...reducers
    }),

    store = createStore(reducer, initialState, compose(applyMiddleware(...reduxMiddlewares)));
  sagaMiddleware.run(RootSaga);
  return store;
};
