import _ from 'lodash';
import React from 'react';
import {unitWithUpperIndex} from '../tools/formatters';

export default class Offer {
  units: any
  reminder: number
  price: number
  new_price: number
  constructor(offer_attributes:any) {
    Object.keys(offer_attributes).forEach(key => this[key] = offer_attributes[key]);
  }

  get baseUnit() {
    return this.units.find(unit => unit.base_line === 1);
  }

  get unitSelectOptions() {
    return this.units.map(unit => ({value: unit.id, label: unitWithUpperIndex(unit.name)}));
  }

  get unitsPrices() {
    return _.sortBy(this.units, ['base_line']).map(unit => ({price: this.priceInUnits(unit), unit: unitWithUpperIndex(unit.name)}));
  }

  get discountPercents() {
    return this.new_price && this.price ? Math.round(100 - (100 / this.price) * this.new_price) : null;
  }


  priceInUnits(unit) {
    const rate = unit.rate;
    return this.price * rate;
  }

  newPriceInUnits(unit) {
    const rate = unit.rate;
    return this.new_price * rate;
  }

  reminderStrInUnits(unitId) {
    const unit = this.units.find(unit => unit.id === unitId);
    if (!unit) return '';
    return <>{this.reminder / unit.rate} {unitWithUpperIndex(unit.name)}</>;
  }

  get reminderStrInBaseUnits() {
    return this.reminderStrInUnits(this.baseUnit?.id)
  }
}
