import ACTIONS from '../constants/actions';

const DEFAULT_PAGINATOR = {
  page: 1,
  per_page: 10
};

export const initialState = {
  articles: [],
  currentArticle: null,
  events: [],
  currentEvent: null,
  stocks: [],
  currentStock: null,
  paginator: {
    ...DEFAULT_PAGINATOR
  }
};

const articlesReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.FETCH_ARTICLES_SUCCESS]() {
      return {
        ...state,
        articles: action.payload.articles,
        paginator: action.payload.paginator
      };
    },
    [ACTIONS.FETCH_ARTICLE_SUCCESS]() {
      return {
        ...state,
        currentArticle: action.payload.article
      };
    },
    [ACTIONS.FETCH_EVENTS_SUCCESS]() {
      return {
        ...state,
        events: action.payload.events,
        paginator: action.payload.paginator
      };
    },
    [ACTIONS.FETCH_EVENT_SUCCESS]() {
      return {
        ...state,
        currentEvent: action.payload.event
      };
    },
    [ACTIONS.FETCH_STOCKS_SUCCESS]() {
      return {
        ...state,
        stocks: action.payload.stocks
      };
    },
    [ACTIONS.FETCH_STOCK_SUCCESS]() {
      return {
        ...state,
        currentStock: action.payload.stock
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default articlesReducer;
