import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const fetchProducts = (rubricId) => ({
  type: ACTIONS.FETCH_PRODUCTS,
  [pendingTask]: begin,
  payload: {rubricId}
});
export const fetchProductsSuccess = (products, pagination) => ({
  type: ACTIONS.FETCH_PRODUCTS_SUCCESS,
  [pendingTask]: end,
  payload: {products, pagination}
});
export const fetchProductsFailed = () => ({
  type: ACTIONS.FETCH_PRODUCTS_FAILED,
  [pendingTask]: end,
});

export const fetchProductsCount = () => ({
  type: ACTIONS.FETCH_PRODUCTS_COUNT
});
export const fetchProductsCountSuccess = (total) => ({
  type: ACTIONS.FETCH_PRODUCTS_COUNT_SUCCESS,
  payload: {total}
});
export const fetchProductsCountFailed = () => ({
  type: ACTIONS.FETCH_PRODUCTS_COUNT_FAILED
});

export const resetProducts = () => ({
  type: ACTIONS.RESET_PRODUCTS
});

export const fetchProduct = (productId: number) => ({
  type: ACTIONS.FETCH_PRODUCT,
  [pendingTask]: begin,
  payload: {productId}
});

export const fetchProductSuccess = (product) => ({
  type: ACTIONS.FETCH_PRODUCT_SUCCESS,
  [pendingTask]: end,
  payload: {product}
});

export const fetchProductFailed = () => ({
  type: ACTIONS.FETCH_PRODUCT_FAILED,
  [pendingTask]: end,
});

export const resetCurrentProduct = () => ({
  type: ACTIONS.RESET_CURRENT_PRODUCT,
});

export const paginatorChanged = (page, per_page) => ({
  type: ACTIONS.SET_PRODUCTS_PAGINATION,
  payload: {
    pagination: {page, per_page}
  }
});

export const fetchProductsFilterContext = (rubricId) => ({
  type: ACTIONS.FETCH_PRODUCTS_FILTERS_CONTEXT,
  payload: {rubricId}
});

export const fetchProductsFilterContextSuccess = (filters) => ({
  type: ACTIONS.FETCH_PRODUCTS_FILTERS_CONTEXT_SUCCESS,
  payload: {filters}
});

export const fetchProductsFilterContextFailed = () => ({
  type: ACTIONS.FETCH_PRODUCTS_FILTERS_CONTEXT_FAILED
});

export const resetProductsFilterContext = () => ({
  type: ACTIONS.RESET_PRODUCTS_FILTERS_CONTEXT
});

export const setProductsFilter = (filterName, filterValue) => ({
  type: ACTIONS.SET_PRODUCTS_FILTER,
  payload: {filterName, filterValue}
});

export const resetProductsFilters = () => ({
  type: ACTIONS.RESET_PRODUCTS_FILTERS
});

export const applyProductsFilters = () => ({
  type: ACTIONS.APPLY_PRODUCTS_FILTERS
});

export const resetProductsFilter = (filterName) => ({
  type: ACTIONS.RESET_PRODUCTS_FILTERS,
  payload: {filterName}
});

export const changeSort = (name, dest) => ({
  type: ACTIONS.CHANGE_PRODUCTS_SORT,
  payload: {name, dest}
});

export const fetchAnalogueProducts = (productId: number) => ({
  type: ACTIONS.FETCH_ANALOGUE_PRODUCTS,
  payload: {productId}
});

export const fetchAnalogueProductsSuccess = (products) => ({
  type: ACTIONS.FETCH_ANALOGUE_PRODUCTS_SUCCESS,
  payload: {products}
});

export const fetchAnalogueProductsFailed = () => ({
  type: ACTIONS.FETCH_ANALOGUE_PRODUCTS_FAILED,
});

export const resetAnalogueProducts = () => ({
  type: ACTIONS.RESET_ANALOGUE_PRODUCTS,
});

export const fetchInterestingProducts = (productId: number) => ({
  type: ACTIONS.FETCH_INTERESTING_PRODUCTS,
  payload: {productId}
});

export const fetchInterestingProductsSuccess = (products) => ({
  type: ACTIONS.FETCH_INTERESTING_PRODUCTS_SUCCESS,
  payload: {products}
});

export const fetchInterestingProductsFailed = () => ({
  type: ACTIONS.FETCH_INTERESTING_PRODUCTS_FAILED,
});

export const resetInterestingProducts = () => ({
  type: ACTIONS.RESET_INTERESTING_PRODUCTS,
});

export const fetchProductReviews = (productId: number) => ({
  type: ACTIONS.FETCH_PRODUCT_REVIEWS,
  payload: {productId}
});

export const fetchProductReviewsSuccess = (reviews) => ({
  type: ACTIONS.FETCH_PRODUCT_REVIEWS_SUCCESS,
  payload: {reviews}
});

export const fetchProductReviewsFailed = () => ({
  type: ACTIONS.FETCH_PRODUCT_REVIEWS_FAILED,
});

export const resetProductReviews = () => ({
  type: ACTIONS.RESET_PRODUCT_REVIEWS,
});
