import React, {useEffect, useState} from 'react';
import {bindActionCreators, compose} from 'redux';
import * as rubricsActions from '../../../actions/rubricsActionCreators';
import * as productsActions from '../../../actions/productsActionCreators';
import {connect} from 'react-redux';
import ProductsList from '../../../components/ProductsList';
import Paginator from '../../../components/Paginator';
import {useUnmount} from 'react-use';
import ProductsFilters from '../../../components/ProductsFilters';
import ProductsSort from '../../../components/ProductsSort';
import MobileProductsFilters from '../../../components/ProductsFilters/MobileFilters';
import Button from '../../../components/Button';
import {productsCountFrom} from '../../../tools/formatters';

const ProductRubric = ({rubric, ...props}) => {
  const
    {possibleProductsCount} = props.productsState,
    filtersOn = !!Object.keys(props.productsState.filters).length,
    handleApplyFilters = () => {
      props.productsActions.applyProductsFilters();
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    },
    countStr = possibleProductsCount ? `${possibleProductsCount} ${productsCountFrom(possibleProductsCount)}` : '',

    [currentRubricId, setCurrentRubricId] = useState(null),
    handlePaginatorChanged = (page, perPage) => {
      props.productsActions.paginatorChanged(page, perPage);
    },
    {is_mobile} = props.applicationState;

  useUnmount(() => {
    props.productsActions.resetProducts();
  });

  useEffect(() => {
    if (rubric.id === currentRubricId) return;

    props.productsActions.fetchProducts(rubric.id);
    props.productsActions.fetchProductsFilterContext(rubric.id);

    setCurrentRubricId(rubric?.id);
  }, [rubric?.id, props.productsActions.fetchProducts]);

  return (
    <div className='two-cols-container'>
      {
        !is_mobile ?
          <div className='two-cols-container__col two-cols-container__col_left'>
            <ProductsFilters />
            {
              filtersOn && possibleProductsCount ?
                <Button primary onClick={handleApplyFilters}>{`Показать ${countStr}`}</Button>
                : null
            }
          </div>
          : null
      }
      <div className='two-cols-container__col two-cols-container__col_right'>
        <>
          <div className='products-sort-and-filters-wrapper'>
            <ProductsSort />
            {
              is_mobile ?
                <MobileProductsFilters />
                : null
            }
          </div>
          <ProductsList products={props.productsState.collection} showSkeleton={!props.productsState.collection_loaded} />
          <Paginator {...props.productsState.paginator} onChange={handlePaginatorChanged}/>
        </>
      </div>
    </div>
  )
}


const
  mapDispatchToProps = (dispatch) => {
    return {
      rubricsActions: bindActionCreators(rubricsActions, dispatch),
      productsActions: bindActionCreators(productsActions, dispatch),
    };
  },
  mapStateToProps = ({rubricsState, railsContext, applicationState, productsState}) => {
    return {
      applicationState,
      rubricsState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(ProductRubric);
