import React from 'react';

const ArticleShow = (props) => {
  return (
    <div className='article-content'>
      <h1>{props.article?.title}</h1>
      <div
        className='article-content__body html-content'
        dangerouslySetInnerHTML={{__html: props.article?.body}}
      />
    </div>
  );
}

export default ArticleShow;
