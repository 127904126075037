import React from 'react';

const SearchIcon = ({width = 32, height = 32, color = '#fff', opacity = '.2'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.2155 22.6881C17.9652 23.3264 16.5491 23.6864 15.049 23.6864C9.98092 23.6864 5.87244 19.5779 5.87244 14.5098C5.87244 9.44173 9.98092 5.33325 15.049 5.33325C20.1171 5.33325 24.2256 9.44173 24.2256 14.5098C24.2256 16.0099 23.8656 17.426 23.2273 18.6763L26.3749 21.8239C27.4827 22.9317 27.4827 24.7279 26.3749 25.8357C25.2671 26.9435 23.4709 26.9435 22.3631 25.8357L19.2155 22.6881ZM21.2027 14.5098C21.2027 17.9084 18.4476 20.6635 15.049 20.6635C11.6504 20.6635 8.8953 17.9084 8.8953 14.5098C8.8953 11.1112 11.6504 8.35612 15.049 8.35612C18.4476 8.35612 21.2027 11.1112 21.2027 14.5098Z" fill={color} fillOpacity={opacity} />
    </svg>
  );
}

export default SearchIcon;
